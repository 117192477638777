<template>
  <ul class="clearfix" v-if="mode === 'default'">
    <draggable v-model="images">
      <li v-for="(img, index) in images" :key="img">
        <div class="selected-image-item-mask">
          <DeleteOutlined @click="onDel(index)" />
        </div>
        <div
          class="selected-image-item-img"
          :style="{
            backgroundImage: `url(${$ali(img, 88)})`,
          }"
        ></div>
      </li>
    </draggable>
    <li class="add-btn-wrap" v-if="maxLength > images.length">
      <div
        class="ant-upload ant-upload-select-picture-card"
        @click="handleShowImgList"
      >
        <span class="ant-upload">
          <PlusOutlined />
          <div class="ant-upload-text">选择图片</div>
        </span>
      </div>
    </li>
  </ul>
  <ImgListModal ref="imgListModalRef" @change="handleChange" />
</template>
<script>
import { ref, provide, watchEffect, reactive, toRefs } from "vue";
import pick from "pick.js";
import { VueDraggableNext as Draggable } from "vue-draggable-next";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons-vue";
import { useControllableValue } from "xz-use";
import ImgListModal from "./ImgListModal.vue";
export default {
  name: "UploadImg",
  components: { PlusOutlined, DeleteOutlined, ImgListModal, Draggable },
  props: {
    maxLength: {
      type: Number,
      default: Infinity,
    },
    size: {
      type: Number,
      default: 10 * 1024, // 默认1M
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    showUploadBtn: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "default",
    },
  },
  setup(props, { expose }) {
    const images = useControllableValue(props, {
      defaultValue: [],
    });

    provide("context", {
      ...pick(toRefs(props), ["maxLength", "modelValue", "size"]),
      images,
    });

    const imgListModalRef = ref();
    // 显示图片列表
    const handleShowImgList = () => {
      imgListModalRef.value?.open(images.value);
    };

    const handleChange = (imgs) => {
      images.value = imgs.slice(-props.maxLength);
    };

    const onDel = (index) => {
      images.value = images.value.filter((img, i) => {
        return index !== i;
      });
    };

    expose({
      open: handleShowImgList,
    });

    return {
      images,
      imgListModalRef,
      handleShowImgList,
      handleChange,
      onDel,
    };
  },
};
</script>
<style lang="less" scoped>
ul {
  padding: 0;
  li {
    cursor: pointer;
    float: left;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    overflow: hidden;
    margin-right: 6px;
    margin-bottom: 6px;
    box-sizing: border-box;
    position: relative;
    background-color: white;
    transition: all 1s;
    /* &.drop-over {
      border: 1px solid #1969f1;
    } */
    &:not(.add-btn-wrap) {
      border: 1px solid #d9d9d9;
      padding: 5px;
    }
    .selected-image-item-img {
      display: block;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }
    &:hover {
      .selected-image-item-mask {
        display: block;
      }
    }
    .selected-image-item-mask {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #00000049;
      text-align: center;
      line-height: 100px;
      color: #fff;
      font-size: 18px;
    }
  }
}
.ant-upload-select-picture-card {
  width: 90px;
  height: 90px;
  margin: 0;
  &::v-deep(i) {
    font-size: 32px;
    color: #999;
  }
  .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }
}
</style>
