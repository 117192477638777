import { httpGet, httpPost } from "@/config/axios-config";
import { stringify } from "querystring";

// 应用详情
export interface XzcloudApplicationInfoParams {
  applicationId?: number;
}
export interface XzcloudApplicationInfoRes {
  /* appkey */
  appKey: string;

  /* 应用类型 */
  applicationType: number;

  /* 应用名称 */
  applicationName: string;

  /* 应用说明 */
  applicationContent: string;

  /* 应用id */
  applicationId: string;

  /* 授权系统数 */
  authorizeSysNum: number;

  /* 回调url */
  callBackUrl: string;

  /* 授权业务回调URL */
  authCallbackUrl: string;

  /* 创建事件 */
  createDate: string;

  /* 数据加密key */
  encodingAESKey: string;

  /* 白名单列表 */
  whiteIpList: string[];

  /* 申请理由 */
  applyReason: string;

  appSecret: string;
}
export const xzcloudApplicationInfo = (params: XzcloudApplicationInfoParams) =>
  httpGet<XzcloudApplicationInfoRes>("/xzcloud/application/info", params);

export interface XzcloudApplicationCurrentInfoParam {
  appId?: number;
}
// 当前应用详情
export const applicationCurrentInfo = (
  params?: XzcloudApplicationCurrentInfoParam
) =>
  httpGet<XzcloudApplicationInfoRes>(
    "/xzcloud/application/currentInfo",
    params
  );

// 重置或获取appSecret
export const xzcloudApplicationRemakeAndGetAppSecret = (params: {
  applicationId: string;
}) =>
  httpPost(
    `/xzcloud/application/remakeAndGetAppSecret?${stringify({ ...params })}`
  );

// 设置回调地址
export interface XzcloudApplicationSetCallbackUrlParams {
  /* 应用id */
  applicationId: string;

  /* 回调地址 */
  callbackUrl: string;
}
export const xzcloudApplicationSetCallbackUrl = (
  params: XzcloudApplicationSetCallbackUrlParams
) => httpPost("/xzcloud/application/setCallbackUrl", params);

// 设置ip白名单
export interface XzcloudApplicationSetIpWhiteListParams {
  /* 应用id */
  applicationId: string;

  /* ip白名单 */
  ipWhiteList: string[];
}
export const xzcloudApplicationSetIpWhiteList = (
  params: XzcloudApplicationSetIpWhiteListParams
) => httpPost("/xzcloud/application/setIpWhiteList", params);

// 设置数据加密key
export interface XzcloudApplicationSetEncodingKeyParams {
  /* 应用id */
  applicationId: string;

  /* 应用id */
  encodingKey: string;
}
export const xzcloudApplicationSetEncodingKey = (
  params: XzcloudApplicationSetEncodingKeyParams
) => httpPost(`/xzcloud/application/setEncodingKey`, params);

// 设置授权业务回调接口
export interface SetAuthCallBackUrlParams {
  /* 应用id */
  applicationId: number;

  /* 回调地址 */
  authCallbackUrl: string;
}
export interface SetAuthCallBackUrlRes {}
export const setAuthCallBackUrl = (params: SetAuthCallBackUrlParams) =>
  httpPost<SetAuthCallBackUrlRes>(
    "/xzcloud/application/setAuthCallBackUrl",
    params
  );
