const { VUE_APP_API_ENV } = process.env;

const Config: Record<string, any> = {
  base: {
    wxWorkAppOrigin: "http://ewechatapptest.xzintl.com",
    ossDomain: "http://statictest.scrm.xzintl.com/",
    marketCloudUrl: "http://scrmtest.xzintl.com",
  },
  dev: {},
  beta: {},
  prod: {
    wxWorkAppOrigin: "http://wxwork.xzintl.com",
    ossDomain: "http://static.gmc.xzintl.com/",
    marketCloudUrl: "http://scrm.xzintl.com",
  },
  prod_yfl: {
    wxWorkAppOrigin: "http://wxwork.eifini.com",
    ossDomain: "https://gmc-oss.eifini.com/",
    marketCloudUrl: "http://scrm.eifini.com",
  },
  prod_abc: {
    wxWorkAppOrigin: "http://wxwork.qbabckids.com.cn",
    ossDomain: "https://gmc-oss.qbabckids.com.cn/",
    marketCloudUrl: "http://scrm.qbabckids.com.cn",
  },
};

export default Object.assign(
  {},
  Config.base,
  Config[VUE_APP_API_ENV || "prod"]
);
