import isFunction from "lodash/isFunction";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { computed, inject, provide, reactive, Ref, ref } from "vue";

const context = Symbol("useForm");
export const ProvideForm = (props) => {
  provide(context, props);
};

//获取勾选上下文
export const InjectForm = () => {
  return inject(context) as {
    reset: () => void;
    form: any;
    formRef: Ref<any>;
    note: Ref<any>;
    getFieldsValue: (data: any) => any;
  };
};

//判断返回的数据是否为空
const isEmpty = (value): boolean => {
  return (
    ["", null, undefined].includes(value as never) ||
    isEqual(value, []) ||
    isEqual(value, {})
  );
};

export const useForm = (initData) => {
  const formRef = ref();
  let note: any = ref({});

  const initValue = isFunction(initData) ? initData : () => cloneDeep(initData); //初始化数据
  const form: Ref<any> = ref(initValue());
  ProvideForm({
    form,
    formRef,
  });

  //重置表单
  const reset = (): void => {
    note.value = {};
    formRef.value.resetFields();
    form.value = initValue();
  };
  const getFieldsValue = (data /* 后台响应的数据 */) => {
    const values = {};
    //过滤掉后台返回的多余的参数和空值
    Object.entries(initValue()).forEach(([key, value]) => {
      values[key] = isEmpty(data[key]) ? value : data[key];
    });
    return values;
  };
  return {
    form,
    formRef,
    reset,
    note,
    getFieldsValue,
  };
};
