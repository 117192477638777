<template>
  <a-modal
    :visible="visible"
    :width="800"
    title="我的图片"
    @ok="handleOk"
    @cancel="handleClose"
  >
    <a-button type="primary" @click="uploadImageModalRef?.open()"
      >上传图片</a-button
    >
    <ul>
      <template v-for="img in tableProps.dataSource" :key="img.fileKey">
        <Var :index="selectedImgs.indexOf(img.fileKey)">
          <template #default="{ index }">
            <li
              :class="{
                'upload-image-item__active': index > -1,
                'upload-image-item__disabled': false,
              }"
              @click="handleSelect(img)"
            >
              <span v-if="index > -1" class="upload-image-item-tag">{{
                index + 1
              }}</span>
              <div
                class="upload-image-item-img"
                :style="{
                  backgroundImage: `url(${$ali(img.fileKey, 88)})`,
                }"
              ></div>
            </li>
          </template>
        </Var>
      </template>
    </ul>
    <a-pagination
      hideOnSinglePage
      size="small"
      style="justify-content: flex-end"
      v-bind="pagination"
    />

    <UploadImageModal ref="uploadImageModalRef" @change="handleChange" />
  </a-modal>
</template>
<script>
import { ref, getCurrentInstance, inject } from "vue";
import { message } from "ant-design-vue";
import { useAntdTable, useModalState } from "xz-use";
import Cfg from "@/config/index";
import Var from "@/components/Var";
import UploadImageModal from "./UploadImageModal";

export default {
  components: { Var, UploadImageModal },
  emits: ["change"],
  setup(props, { emit, expose }) {
    const { proxy } = getCurrentInstance();

    const { visible, handleOpen, handleClose } = useModalState({
      afterOpen: (images) => {
        selectedImgs.value = [...images];
        search.submit();
      },
    });

    const selectedImgs = ref([]);
    // 图片列表
    const { tableProps, pagination, search } = useAntdTable(
      (pageParams, formData) => {
        return proxy
          .$httpPost("/common/uploadFileEntity/findUploadFile", {
            ...pageParams,
            ...formData,
          })
          .then((data) => {
            return {
              total: data.total,
              list: data.records.map((img) => {
                return {
                  ...img,
                  fileKey: Cfg.ossDomain + img.fileKey,
                };
              }),
            };
          });
      },
      {
        defaultPageSize: 28,
        manual: true,
        initialData: {
          list: [],
          total: 0,
        },
      }
    );

    const context = inject("context");

    const handleSelect = ({ fileKey }) => {
      const index = selectedImgs.value.indexOf(fileKey);
      if (index > -1) {
        selectedImgs.value = selectedImgs.value.filter((_, i) => {
          return index !== i;
        });
      } else {
        if (context.maxLength.value > selectedImgs.value.length) {
          // 添加
          selectedImgs.value = [...selectedImgs.value, fileKey];
        } else {
          // 覆盖第一个
          selectedImgs.value = [...selectedImgs.value.slice(1), fileKey];
        }
      }
    };

    const handleOk = () => {
      if (!selectedImgs.value.length) {
        return message.error("请选择图片～");
      }
      emit("change", selectedImgs.value);
      visible.value = false;
    };

    expose({
      open: handleOpen,
      close: handleClose,
    });

    const uploadImageModalRef = ref();

    const handleChange = (imgs) => {
      emit("change", [...selectedImgs.value, ...imgs]);
      visible.value = false;
    };

    return {
      context,
      visible,
      handleOk,
      handleClose,
      selectedImgs,
      tableProps,
      pagination,
      handleSelect,
      uploadImageModalRef,
      handleChange,
    };
  },
};
</script>
<style lang="less" scoped>
ul {
  margin: 16px 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  li {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 100px;
    height: 100px;
    overflow: hidden;
    border: 1px dashed #d9d9d9;
    padding: 5px;
    margin-right: 6px;
    margin-bottom: 6px;
    box-sizing: border-box;
    border-radius: 5px;
    position: relative;
    &.upload-image-item__active {
      border: 1px solid #1969f1;
    }
    &.upload-image-item__disabled {
      opacity: 0.5;
    }
    .upload-image-item-tag {
      position: absolute;
      right: 0;
      top: 0;
      color: #fff;
      width: 20px;
      height: 20px;
      background: #1969f1;
      text-align: center;
      line-height: 20px;
    }
    .upload-image-item-img {
      display: block;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
}
</style>
