import { getCurrentInstance } from "vue";
import { useRequest } from "xz-use";
import { getSuffix, getHashids, getImageSize } from "@/utils/index";

//在上传之前转换文件
export const transformFileImage = async (file, ossData) => {
  let filename = genFileName();
  let size = await getImageSize(file);
  if (size) {
    filename += `-w${size.w}_h${size.h}`;
  }
  file.url = ossData.dir + filename + getSuffix(file.name);
  return file;
};

export const transformFile = async (file, ossData) => {
  let filename = genFileName();
  file.url = ossData.dir + filename + getSuffix(file.name);
  return file;
};

export const getExtraData = (file, ossData) => {
  return {
    key: file.url,
    OSSAccessKeyId: ossData?.accessid,
    policy: ossData?.policy,
    Signature: ossData?.signature,
    success_action_status: "200",
    callback: ossData?.callback,
  };
};

const genFileName = () => {
  const hashids = getHashids(
    "xz-aliyunoss-upload",
    0,
    "abcdefghijklmnopqrstuvwxyz1234567890"
  );
  return (
    "file_" +
    hashids.encode(Date.now() + (Math.random() * (999 - 100) + 100).toFixed(0))
  );
};

const useUpload = () => {
  const { proxy } = getCurrentInstance();
  // 获取上传凭证
  const { run: genPolicyRun } = useRequest(
    () => proxy.$httpGet("/common/oss/getPolicy"),
    {
      manual: true,
      initialData: {},
    }
  );

  return {
    genPolicyRun,
  };
};

export default useUpload;
