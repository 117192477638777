<template>
  <a-modal
    :visible="visible"
    :width="800"
    title="上传图片"
    :maskClosable="false"
    @ok="handleUpload"
    @cancel="handleClose"
    okText="上传"
    :confirmLoading="uploading"
  >
    <a-upload
      accept="image/*"
      @preview="handlePreview"
      list-type="picture-card"
      multiple
      v-model:file-list="fileList"
      :before-upload="() => false"
      :disabled="uploading"
    >
      <div v-if="fileList.length < context.maxLength.value">
        <PlusOutlined style="font-size: 24px" />
        <div class="ant-upload-text">选择图片</div>
      </div>
    </a-upload>
    <p :style="{ color: '#969799', marginTop: 16 }">
      支持 .jpg, .gif, .png, .bmp 格式，
      {{
        Infinity > context.maxLength.value
          ? `最多${context.maxLength.value}张，`
          : ""
      }}单个图片不超过 {{ getSizeStr(context.size.value) }} 。
    </p>
  </a-modal>

  <a-modal
    :visible="previewVisible"
    :footer="null"
    @cancel="previewVisible = false"
  >
    <img alt="预览" style="width: 100%" :src="previewImage" />
  </a-modal>
</template>
<script>
import { getCurrentInstance, inject, ref } from "vue";
import { message, Modal } from "ant-design-vue";
import { useModalState } from "xz-use";
import { PlusOutlined } from "@ant-design/icons-vue";
import Cfg from "@/config/index";
import useUpload, { transformFileImage, getExtraData } from "./use/useUpload";

// size:kb
const getSizeStr = (size) => {
  return size < 1024 ? `${size}KB` : `${size / 1024}MB`;
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default {
  components: { PlusOutlined },
  setup(props, { emit, expose }) {
    const { proxy } = getCurrentInstance();

    const { visible, handleOpen, handleClose } = useModalState({
      beforeClose: () => {
        fileList.value = [];
      },
    });

    expose({
      open: handleOpen,
      close: handleClose,
    });

    const fileList = ref([]);
    const uploading = ref(false);

    const context = inject("context");

    // ====================== 上传 ======================
    const { genPolicyRun } = useUpload();

    // 上传图片
    const uploadImgs = async (ossData) => {
      const imgs = [];
      for (let i = 0, len = fileList.value.length; i < len; i++) {
        let file = fileList.value[i];
        if (file.status) continue;
        // 修改文件名称
        file = await transformFileImage(file, ossData);
        const extraData = getExtraData(file, ossData);
        const form = new FormData();
        // 文件原始名称
        form.append("x:file_name", file.name);
        Object.keys(extraData).forEach((key) => {
          form.append(key, extraData[key]);
        });
        form.append("file", file.originFileObj);
        // 设置进度条
        file.status = "uploading";
        file.percent = 0;
        await proxy.$httpPost(ossData.host, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            if (progressEvent.lengthComputable) {
              const percent =
                ((progressEvent.loaded / progressEvent.total) * 100) | 0;

              file.status = percent === 100 ? "done" : file.status;
              file.percent = percent;
            }
          },
        });
        file.url = Cfg.ossDomain + file.url;
        imgs.push(file.url);
      }
      return imgs;
    };

    const handleUpload = async () => {
      for (let file of fileList.value) {
        if (file.size > context.size.length * 1024) {
          return message.error(
            `文件不能超过${getSizeStr(context.size.length)}`
          );
        }
      }
      if (
        !fileList.value.filter((file) => {
          return !file.status;
        }).length
      ) {
        return message.error("请选择图片～");
      }
      uploading.value = true;

      const ossData = await genPolicyRun();
      const imgs = await uploadImgs(ossData);

      uploading.value = false;
      emit("change", imgs);
      handleClose();
    };

    const previewVisible = ref(false);
    const previewImage = ref("");

    // 预览
    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      previewImage.value = file.url || file.preview;
      previewVisible.value = true;
    };

    return {
      visible,
      handleClose,
      fileList,
      uploading,
      handleUpload,
      context,
      getSizeStr,
      handlePreview,
      previewVisible,
      previewImage,
    };
  },
};
</script>
